import React, { useState } from 'react'

export default function ContactForm({ title }) {

    const blankForm = {
        name: "",
        subject: "",
        email: "",
        message: "",
        phone: ""
    }
    const [formVal, setFormVal] = useState(blankForm)



    const handleChange = e => setFormVal({ ...formVal, [e.target.name]: e.target.value });
    return (
        <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/success" className="py-10 bg-gray-darkest">
            <div className="flex flex-col items-center text-center">
                <div className="font-rubik font-bold text-4xl text-gray-lightest uppercase">{title}</div>
                <p className="font-robot font-medium text-gray-lightest">Please fill out the form below to request your consultation.</p>
            </div>
            <div className="flex justify-center">
                <input type="hidden" name="form-name" value="contact" />
                <div hidden aria-hidden="true">
                    <label>
                        Don’t fill this out if you're human:
         <input name="bot-field" />
                    </label>
                </div>
                <div className="md:w-2/3 md:px-4">
                    <div className="contact-form">
                        <div className="sm:flex sm:flex-wrap -mx-3">
                            <div className="sm:w-1/2 px-3 mb-6">
                                <label className="label text-gray-light py-4 font-medium font-roboto">Subject:</label><input className="border-2 rounded px-3 py-1 w-full focus:border-yellow input" required value={formVal.subject || ""} type="text" name="subject" onChange={handleChange} />

                            </div>
                            <div className="sm:w-1/2 px-3 mb-6">
                                <label className="label text-gray-light py-4 font-medium font-roboto">Name:</label><input className="border-2 rounded px-3 py-1 w-full focus:border-yellow input" required value={formVal.name || ""} type="text" name="name" onChange={handleChange} />
                            </div>
                            <div className="sm:w-1/2 px-3 mb-6">
                                <label className="label text-gray-light py-4 font-medium font-roboto">Email:</label><input className="border-2 rounded px-3 py-1 w-full focus:border-yellow input" required value={formVal.email || ""} type="email" name="email" onChange={handleChange} />
                            </div>
                            <div className="sm:w-1/2 px-3 mb-6">
                                <label className="label text-gray-light py-4 font-medium font-roboto">Phone Number:</label><input className="border-2 rounded px-3 py-1 w-full focus:border-yellow input" required value={formVal.phone || ""} type="phone" name="phone" onChange={handleChange} />
                            </div>
                            <div className="sm:w-full px-3">
                                <label className="label text-gray-light py-4 font-medium font-roboto">Project Information:</label><textarea className="border-2 rounded px-3 py-1 w-full h-40 focus:border-yellow input" required name="message" value={formVal.message || ""} onChange={handleChange}></textarea>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 md:mt-12">
                            <button type="submit" className="border-2 border-yellow rounded px-6 py-2 text-yellow hover:bg-indigo-600 hover:text-white transition-colors duration-300">
                                SEND
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    )
}


