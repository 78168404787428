import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const ServiceItem = ({ data }) => {

    const { childContentfulContentModulesParagraphTextNode: { childMarkdownRemark: { html } }, title, subtitle, componentImage } = data
  

    const image = getImage(componentImage)
    return (
        <div className="flex flex-wrap items-center lg:flex-row px-10  py-2 md:py-10">
        <div data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease" className="w-full md:w-1/2">
                     <GatsbyImage alt="VM Construction Construction & Renovation Service" className="shadow-xl" image={image} />

        </div>
        <div className="w-full md:w-1/2 md:pr-4 md:pl-10 pt-10 pb-4 md:pt-0">
        <div className="font-bold font-rubik text-gray-dark uppercase">{title}</div>
              <div className="font-medium font-rubik py-2">{subtitle}</div>
               <div className="font-roboto break-normal" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>

    </div>
     
    );
}

export default ServiceItem