import React from "react";
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionBanner from '../components/SectionBanner'
import ServiceItem from '../components/Services/ServiceItem'
import ContactForm from '../components/Contact/ContactForm'
import SEO from '../components/SEO'


const Services = ({ data }) => {
    const components = data.contentfulPage.components
    const bannerData = components[0]

    return (
       <Layout>
         <SEO title="VM Construction | Our Services" description="VM Construction has been providing home solutions for over 20 years, supplying professional build and property maintenance services to residential clients throughout all of Northamptonshire." />
           <SectionBanner data={bannerData} />
           <ServiceItem data={components[1]}/>
           <ServiceItem data={components[2]}/>
           <ServiceItem data={components[3]}/>
           <ServiceItem data={components[4]}/>
           <ContactForm title="Request Your Consultation" subtitle="" />
       </Layout>
    );
}



export const query = graphql`
query ServicePage {
  contentfulPage(contentful_id: {eq: "1h0Vbs3EhQRfF8Wto1Kxl9"}) {
    id
    title
    description
    components {
      ... on ContentfulContentModules {
        title
        subtitle
        componentImage {
          gatsbyImageData(quality: 90, width: 800)
        }
        childContentfulContentModulesParagraphTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulBanner {
        title
        subtitle
        bannerImage {
          gatsbyImageData(quality: 90, width: 1200)
        }
      }
    }
  }
}
`

export default Services